import { Button, Col, Form, Row, Upload } from 'antd'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { connect } from 'react-redux'
import LanguagesMenu from '~/components/LanguagesMenu'
import TextField from '~/components/TextField'
// import './styles.scss'

export const SignupForm = ({
  t,
  type = 'account',
  onSubmit,
  loading,
  user: { token, first_name, last_name, email, phone },
}) => {
  const router = useRouter()
  const [form] = Form.useForm()

  const handleChange = ({ file }) => {
    form.setFieldsValue({ attachment: file })
  }

  const handleSubmit = (values) => {
    if (!token) {
      onSubmit(values)
    } else {
      onSubmit({
        ...values,
        first_name,
        last_name,
        email,
        phone,
      })
    }
  }

  return (
    <Form
      layout={!token ? 'horizontal' : 'vertical'}
      form={form}
      name="basic"
      onFinish={handleSubmit}
      onFinishFailed={() => {}}
    >
      <div className="login-container">
        {!token && (
          <>
            <Form.Item
              name="last_name"
              rules={[
                { required: true, message: t('lastnameEmptyErr') },
                { max: 100, type: 'string', message: t('lastnameTooLong') },
              ]}
            >
              <TextField
                onChange={(e) => form.setFieldsValue({ last_name: e.target.value })}
                label={t('lastname')}
              />
            </Form.Item>
            <Form.Item
              name="first_name"
              rules={[
                { required: true, message: t('firstnameEmptyErr') },
                { max: 100, type: 'string', message: t('firstnameTooLong') },
              ]}
            >
              <TextField
                onChange={(e) => form.setFieldsValue({ first_name: e.target.value })}
                label={t('firstname')}
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: t('emailEmptyErr') },
                { type: 'email', message: t('emailInvalidErr') },
              ]}
            >
              <TextField
                onChange={(e) => form.setFieldsValue({ email: e.target.value })}
                label={t('email')}
              />
            </Form.Item>
            <Form.Item name="phone" rules={[{ required: true, message: t('phoneEmptyErr') }]}>
              <TextField
                onChange={(e) => form.setFieldsValue({ phone: e.target.value })}
                label={t('phone')}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: t('passwordEmptyErr'),
                },
                {
                  min: 6,
                  message: t('passwordLessThan6Err'),
                },
              ]}
            >
              <TextField
                type="password"
                onChange={(e) => form.setFieldsValue({ password: e.target.value })}
                label={t('password')}
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: t('confirmPasswordEmptyErr'),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error(t('passwordNotMatchErr')))
                  },
                }),
              ]}
            >
              <TextField
                type="password"
                onChange={(e) => form.setFieldsValue({ confirmPassword: e.target.value })}
                label={t('confirm-password')}
              />
            </Form.Item>
          </>
        )}
        {type === 'department' && (
          <Form.Item
            name="attachment"
            label={t('attachment-doc')}
            rules={[
              {
                required: true,
                message: t('attachmentEmptyErr'),
              },
            ]}
            className={`${token && 'upload-center'} upload-form-item`}
          >
            <Upload
              beforeUpload={() => false}
              maxCount={1}
              onChange={handleChange}
              accept=".doc,.docx"
            >
              <Button type={'primary'} style={{ margin: 8 }}>
                <a
                  onClick={(e) => e.stopPropagation()}
                  style={{ color: 'white', fontWeight: 600 }}
                  href={`/assets/files/${router.locale || 'en'}/dkgh.docx`}
                >
                  {t('attachment-example')}
                </a>
              </Button>
              {token && <br />}
              <Button>{t('upload-button')}</Button>{' '}
            </Upload>
          </Form.Item>
        )}
        {type === 'account' && !token && (
          <div className="agreement">
            {t('condition-1-1')} <span className="policy">{t('condition-of-use')}</span>{' '}
            {t('condition-1-2')} <span className="policy">{t('private-policy')}</span>{' '}
            {t('condition-1-3')}
          </div>
        )}
        <Row justify={!token ? 'space-between' : 'center'} style={{ marginTop: 8 }}>
          <Col span={12}>
            <Button
              loading={loading}
              htmlType={'submit'}
              style={{}}
              className="register-btn"
              type="primary"
            >
              {t(type === 'account' ? 'create-account' : 'register')}
            </Button>
          </Col>
          <Col span={!token ? 8 : 0}>
            <LanguagesMenu />
          </Col>
        </Row>
      </div>
    </Form>
  )
}

SignupForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['account', 'department']),
}

const mapStateToProps = (state) => ({
  loading: state.signup?.loading,
  user: state.user,
})

export default connect(mapStateToProps)(memo(SignupForm))
